"use strict";

let body = document.querySelector("body"),
	header = document.querySelectorAll("header")[0],
	scrollPosition = 0;


// Handle mobile header's class based on scroll position including page load.
function handleMobileHeader() {
	scrollPosition = window.scrollY;

	if (scrollPosition >= 1) {
		header.classList.add("mobile-header-scrolled");
	} else {
		header.classList.remove("mobile-header-scrolled");
	}
}

window.addEventListener("load", handleMobileHeader);
window.addEventListener("scroll", handleMobileHeader);



// Add class to header when mobile menu is open
let mobileMenuToggle = document.querySelector(".fl-menu-mobile-toggle");

function detectMobileHeader() {
	setTimeout(function(){
		if (mobileMenuToggle.classList.contains("fl-active")) {
			header.classList.add("mobile-header-open");
			body.classList.add("no-scroll");
		} else {
			header.classList.remove("mobile-header-open");
			body.classList.remove("no-scroll");
		}
	}, 100);
}

mobileMenuToggle.addEventListener("click", detectMobileHeader);



// Mobile styles based on header height
const mediaQuery = window.matchMedia("(max-width: 767px)");

function mobileHeaderHeightOffsets(x) {

	if (x.matches) {
		var headerHeight =  document.querySelector("header.fl-builder-content").offsetHeight;
		// Push content down since header is absolute
		document.querySelector(".fl-page").style.marginTop = headerHeight + "px";
	} else {
		// Push content down since header is absolute
		document.querySelector(".fl-page").style.marginTop = "0px";
	}
}

window.addEventListener("load", mobileHeaderHeightOffsets(mediaQuery));
mediaQuery.addEventListener("change", mobileHeaderHeightOffsets);


// Image Comparison Slider
if (document.querySelector(".image-sliders")){
	const swiperImages = new Swiper(".image-sliders", {
		allowTouchMove: false,
		autoplay: {
			delay: 5000,
			//pauseOnMouseEnter: true // doesn't restart on mouseExit
		},
		grabCursor: false,
		lazy: false,
		loop: false,
		navigation: {
			nextEl: ".image-sliders-next",
			prevEl: ".image-sliders-prev",
		},
		pagination: {
			clickable: true,
			el: ".image-sliders-pagination",
			type: "bullets",
		},
		preloadImages: false,
		slideClass: "fl-module-pp-image-comparison",
		slidesPerView: 1,
		speed: 500,
		spaceBetween: 0,
		wrapperClass: "fl-col-content",
	});

	document.querySelectorAll(".image-sliders .fl-module-pp-image-comparison").forEach(item => {
		item.addEventListener("mousedown", function () {
			//console.log('stop autoplay');
			swiperImages.autoplay.stop();
		});
		item.addEventListener("mouseup", function () {
			//console.log('start autoplay');
			swiperImages.autoplay.start();
		});
	});
	//jQuery('.image-sliders').on('mouseenter', function(e){
	//	console.log('stop autoplay');
	//	swiperImages.autoplay.stop();
	//});
	//jQuery('.image-sliders').on('mouseleave', function(e){
	//	console.log('start autoplay');
	//	swiperImages.autoplay.start();
	//});
}

if (document.querySelector(".swiper__timeline")){
	// Timeline expand images in modal
	document.querySelector(".swiper__timeline__modal").style.height = window.innerHeight + "px";

	document.querySelectorAll(".swiper__timeline .col-photo").forEach(item => {
		item.addEventListener("click", function () {
			var $img = item.querySelector("img").src;
			openModal($img);
		});
	});
}

function openModal($img) {
	document.querySelector(".swiper__timeline__modal .content img").src = $img;
	document.querySelector(".swiper__timeline__modal").style.display = "block";
}

function closeModal() {
	document.querySelector(".swiper__timeline__modal").style.display = "none";
	document.querySelector(".swiper__timeline__modal .content img").src = "";
}

